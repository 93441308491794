<template>
    <div>404 页面不存在</div>
</template>

<script lang="javascript">
    export default {
        name: "NotFound",
        data() {
            return {
            }
        },
        created() {

        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>

</style>
